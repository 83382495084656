import React from 'react';
import {
  Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
  TablePagination, TableRow, TableSortLabel, Toolbar, Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

interface InvoiceData {
  invoiceNumber: string;
  dateIssued: string;
  dueDate: string;
  amount: string;
  status: string;
}
const invoiceRows: InvoiceData[] = [
  {
    invoiceNumber: 'INV-1001',
    dateIssued: '2024-01-01',
    dueDate: '2024-01-15',
    amount: '$1,000',
    status: 'Paid',
  },
  {
    invoiceNumber: 'INV-1002',
    dateIssued: '2024-01-05',
    dueDate: '2024-01-20',
    amount: '$2,000',
    status: 'Pending'
  },
  {
    invoiceNumber: 'INV-1003',
    dateIssued: '2023-01-10',
    dueDate: '2023-01-25',
    amount: '$5,000',
    status: 'Paid',
  },
  {
    invoiceNumber: 'INV-1004',
    dateIssued: '2023-02-15',
    dueDate: '2023-03-02',
    amount: '$6,500',
    status: 'Pending',
  },
  {
    invoiceNumber: 'INV-1005',
    dateIssued: '2023-03-20',
    dueDate: '2023-04-04',
    amount: '$7,200',
    status: 'Paid',
  },
  {
    invoiceNumber: 'INV-1006',
    dateIssued: '2023-04-10',
    dueDate: '2023-04-25',
    amount: '$8,500',
    status: 'Overdue',
  },
  {
    invoiceNumber: 'INV-1007',
    dateIssued: '2023-05-05',
    dueDate: '2023-05-20',
    amount: '$9,300',
    status: 'Paid',
  },
  {
    invoiceNumber: 'INV-1008',
    dateIssued: '2023-06-12',
    dueDate: '2023-06-27',
    amount: '$11,000',
    status: 'Pending',
  },
  {
    invoiceNumber: 'INV-1009',
    dateIssued: '2023-07-15',
    dueDate: '2023-07-30',
    amount: '$12,500',
    status: 'Overdue',
  },
  {
    invoiceNumber: 'INV-1010',
    dateIssued: '2023-08-20',
    dueDate: '2023-09-04',
    amount: '$14,000',
    status: 'Paid',
  },
  {
    invoiceNumber: 'INV-1011',
    dateIssued: '2023-09-25',
    dueDate: '2023-10-10',
    amount: '$15,500',
    status: 'Pending',
  },
  {
    invoiceNumber: 'INV-1012',
    dateIssued: '2023-10-30',
    dueDate: '2023-11-14',
    amount: '$17,000',
    status: 'Paid',
  },
  {
    invoiceNumber: 'INV-1013',
    dateIssued: '2023-11-10',
    dueDate: '2023-11-25',
    amount: '$18,500',
    status: 'Overdue',
  },
  {
    invoiceNumber: 'INV-1014',
    dateIssued: '2023-12-05',
    dueDate: '2023-12-20',
    amount: '$20,000',
    status: 'Paid',
  },
  {
    invoiceNumber: 'INV-1015',
    dateIssued: '2024-01-10',
    dueDate: '2024-01-25',
    amount: '$22,000',
    status: 'Pending',
  },
  {
    invoiceNumber: 'INV-1016',
    dateIssued: '2024-02-15',
    dueDate: '2024-03-01',
    amount: '$24,000',
    status: 'Paid',
  },
  {
    invoiceNumber: 'INV-1017',
    dateIssued: '2024-03-20',
    dueDate: '2024-04-04',
    amount: '$26,000',
    status: 'Overdue',
  },
  {
    invoiceNumber: 'INV-1018',
    dateIssued: '2024-04-10',
    dueDate: '2024-04-25',
    amount: '$28,000',
    status: 'Paid',
  },
  {
    invoiceNumber: 'INV-1019',
    dateIssued: '2024-05-15',
    dueDate: '2024-05-30',
    amount: '$30,000',
    status: 'Pending',
  },
  {
    invoiceNumber: 'INV-1020',
    dateIssued: '2024-06-20',
    dueDate: '2024-07-05',
    amount: '$33,000',
    status: 'Paid',
  },
  {
    invoiceNumber: 'INV-1021',
    dateIssued: '2024-07-05',
    dueDate: '2024-07-20',
    amount: '$36,000',
    status: 'Overdue',
  },
  {
    invoiceNumber: 'INV-1022',
    dateIssued: '2024-07-20',
    dueDate: '2024-08-04',
    amount: '$40,000',
    status: 'Pending',
  }
];


interface HeadCell {
  disablePadding: boolean;
  id: keyof InvoiceData;
  label: string;
  numeric: boolean;
}

const invoiceHeadCells: readonly HeadCell[] = [
  { id: 'invoiceNumber', numeric: false, disablePadding: false, label: 'Invoice Number' },
  { id: 'dateIssued', numeric: false, disablePadding: false, label: 'Date Issued' },
  { id: 'dueDate', numeric: false, disablePadding: false, label: 'Due Date' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Amount' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  order: Order;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof InvoiceData) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof InvoiceData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {invoiceHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: 'bold',
              backgroundColor: '#00594F',
              borderBottom: '2px solid #E0E0E0',
              color: '#FFFFFF', // Ensures the text color is white
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{
                '&.Mui-active': {
                  color: '#FFFFFF', // Ensures the text color is white when active
                },
                '& .MuiTableSortLabel-icon': {
                  color: '#FFFFFF !important', // Ensures the sort icon color is white
                },
                color: '#FFFFFF', // Ensures the text color is white
              }}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function InvoiceTable() {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof InvoiceData>('invoiceNumber');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof InvoiceData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () => stableSort(invoiceRows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage],
  );

  const emptyRows = rowsPerPage - visibleRows.length;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, borderRadius: 2 }}>
        <TableContainer sx={{ borderRadius: 2 }}>
          <Table sx={{ minWidth: 750, width: '100%' }} aria-labelledby="tableTitle">
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `invoice-table-checkbox-${index}`;

                return (
                  <TableRow hover tabIndex={-1} key={row.invoiceNumber} sx={{ cursor: 'pointer' }}>
                    <TableCell component="th" id={labelId} scope="row" padding="none" align="right">
                      {row.invoiceNumber}
                    </TableCell>
                    <TableCell align="right">{row.dateIssued}</TableCell>
                    <TableCell align="right">{row.dueDate}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={invoiceRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
