import React from "react";
import { Box, Typography, TextField, Button, Link, Grid } from "@mui/material";
import {
  GitGraphIcon,
  CandlestickChartIcon,
  WalletCardsIcon,
  FileDigitIcon,
  BadgeInfoIcon,
  BookOpenTextIcon,
} from "lucide-react";
import SignupCard from "./SignUpCard";
import logo from "../images/iqana_logo_rgb_pos.svg";
import bgImage from "../images/iqana_pattern_box_3.png"; // Import your background image

const Signup: React.FC = () => {
  return (
    <Grid container sx={{ height: "100vh" }}>
      {/* Left Side: Signup Form */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: "#f4f4f4",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "35rem", // Wider form
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: "1rem",
            }}
          >
            <img
              src={logo}
              alt="Company Logo"
              style={{
                height: "15vh",
                marginBottom: "1rem",
              }}
            />
          </Box>

          <Typography
            variant="h4"
            sx={{
              mb: "1rem",
              color: "#00594F",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Create an account
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mb: "2rem",
              color: "#00594F",
              textAlign: "center",
            }}
          >
            Already have an account?{" "}
            <Link
              href="/signin"
              sx={{
                color: "#00F25E",
                textDecoration: "underline",
              }}
            >
              Sign in
            </Link>
          </Typography>
          <TextField
            fullWidth
            label="Name"
            placeholder="John Doe"
            variant="outlined"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#00594F" },
              },
              "& .MuiInputLabel-root": { color: "#00594F" },
            }}
          />
          <TextField
            fullWidth
            label="Email"
            type="email"
            placeholder="m@example.com"
            variant="outlined"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#00594F" },
              },
              "& .MuiInputLabel-root": { color: "#00594F" },
            }}
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#00594F" },
              },
              "& .MuiInputLabel-root": { color: "#00594F" },
            }}
          />
          <TextField
            fullWidth
            label="Repeat Password"
            type="password"
            variant="outlined"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#00594F" },
              },
              "& .MuiInputLabel-root": { color: "#00594F" },
            }}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: "2rem",
              backgroundColor: "#00F25E",
              color: "#00594F",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#00c94e",
              },
            }}
          >
            Sign up
          </Button>
        </Box>
      </Grid>

      {/* Right Side: Features Section */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 89, 79, 1), rgba(0, 89, 79, 0.75)), url(${bgImage})`,
          backgroundSize: "cover",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
        }}
      >
        <Box sx={{ maxWidth: "80%", width: "100%" }}>
          <Typography
            variant="h3"
            sx={{ color: "white", fontWeight: "bold", mb: 4 }}
          >
            Features
          </Typography>
          <Typography variant="body1" sx={{ color: "white", mb: 4 }}>
            Discover the powerful features that make our app a game-changer for
            you.
          </Typography>
          <Grid container spacing={3} columns={12}>
            {[
              {
                title: "Balances Monitoring",
                description:
                  "Keep it cool with our real-time balance monitoring.",
                icon: <GitGraphIcon className="h-12 w-12 text-[#00594F]" />,
                newLabel: true,
                newLabelText: "New",
              },
              {
                title: "Portfolio Activity",
                description:
                  "Understand the performance with our detailed activity tracking.",
                icon: (
                  <CandlestickChartIcon className="h-12 w-12 text-[#00594F]" />
                ),
                newLabel: true,
                newLabelText: "New",
              },
              {
                title: "Invoice Made Easy",
                description:
                  "Access to all the invoices in one place at one click.",
                icon: <WalletCardsIcon className="h-12 w-12 text-[#00594F]" />,
                newLabel: true,
                newLabelText: "New",
              },
              {
                title: "Data Extraction",
                description:
                  "Easily extract and analyze your activity data with our data extraction tools.",
                icon: <FileDigitIcon className="h-12 w-12 text-[#00594F]" />,
                newLabel: true,
                newLabelText: "Next",
              },
              {
                title: "Advanced insights & KPI",
                description: "Advanced insights to track your progress.",
                icon: <BadgeInfoIcon className="h-12 w-12 text-[#00594F]" />,
                newLabel: true,
                newLabelText: "Next",
              },
              {
                title: "Market in Depth Reports",
                description:
                  "Dive deep into market trends and analysis with our comprehensive reports.",
                icon: <BookOpenTextIcon className="h-12 w-12 text-[#00594F]" />,
                newLabel: true,
                newLabelText: "Next",
              },
            ].map((feature, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <SignupCard
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                  newLabel={feature.newLabel}
                  newLabelText={feature.newLabelText}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Signup;
