// src/components/CustomSwitchComponent.tsx
import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/system';

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem', // Smaller font size
    fontStyle: 'italic',  // Italic text
  },
}));

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#00594F', // Color when the switch is on
    '&:hover': {
      backgroundColor: '#00594F33', // Lighter color on hover
    },
  },
  '& .MuiSwitch-switchBase': {
    color: '#F4F4F4', // Color when the switch is off
    '&:hover': {
      backgroundColor: '#F4F4F433', // Lighter color on hover
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#F4F4F4', // Track color when the switch is off
    opacity: 1,
  },
  '& .Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#00594F', // Track color when the switch is on
  },
}));

interface CustomSwitchComponentProps {
  dense: boolean;
  handleChangeDense: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomSwitchComponent: React.FC<CustomSwitchComponentProps> = ({ dense, handleChangeDense }) => {
  return (
    <CustomFormControlLabel
      control={
        <CustomSwitch
          checked={dense}
          onChange={handleChangeDense}
        />
      }
      label="Compact table"
    />
  );
};

export default CustomSwitchComponent;
