import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../@/components/ui/card";
import {
  FileTextIcon,
  ReceiptIcon,
  ClockIcon,
  BarChartIcon,
  DownloadIcon,
} from "lucide-react";
import Counter from "./Counter"; // Import the Counter component
import InvoiceTable from "./InvoiceTable";

const Invoices: React.FC = () => {
  return (
    <div
      className="p-8 min-h-screen"
      style={{
        background: "linear-gradient(to bottom, white, #F4F4F4)",
      }}
    >
      <h1
        className="text-4xl mb-8"
        style={{ color: "#00594F", marginTop: "1.5vh" }}
      >
        All Invoices In <span style={{ fontWeight: "bold" }}>One Click</span>
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
        <Card
          className="shadow-lg rounded-2xl"
          style={{
            background: "linear-gradient(to bottom, white, #F4F4F4)",
          }}
        >
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-3 pb-2">
            <div className="flex flex-col">
              <CardTitle className="text-sm font-big">Total Invoices</CardTitle>
              <div className="text-2xl font-bold">
                <Counter from={0} to={14} />
              </div>
              <p className="text-xs text-muted-foreground">
                For a total of $94k
              </p>
            </div>
            <FileTextIcon className="h-16 w-16" style={{ color: "#00594F" }} />
          </CardHeader>
        </Card>
        <Card
          className="shadow-lg rounded-2xl"
          style={{
            background: "linear-gradient(to bottom, white, #F4F4F4)",
          }}
        >
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-3 pb-2">
            <div className="flex flex-col">
              <CardTitle className="text-sm font-big">Last Invoice</CardTitle>
              <div className="text-2xl font-bold">$9,668.32</div>
              <p className="text-xs text-muted-foreground">
                Issued on 2023-07-01
              </p>
            </div>
            <ReceiptIcon
              className="h-16 w-16 text-muted-foreground"
              style={{ color: "#00594F" }}
            />
          </CardHeader>
        </Card>
        <Card
          className="shadow-lg rounded-2xl"
          style={{
            background: "linear-gradient(to bottom, white, #F4F4F4)",
          }}
        >
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-3 pb-2">
            <div className="flex flex-col">
              <CardTitle className="text-sm font-big">
                Pending Invoices
              </CardTitle>
              <div className="text-2xl font-bold">0</div>
              <p className="text-xs text-muted-foreground">
                No actions required
              </p>
            </div>
            <ClockIcon
              className="h-16 w-16 text-muted-foreground"
              style={{ color: "#00594F" }}
            />
          </CardHeader>
        </Card>
        <button
          className="shadow-lg rounded-2xl flex items-center justify-between p-3"
          style={{
            background: "linear-gradient(to bottom, white, #F4F4F4)",
            cursor: "pointer",
            border: "2px solid #00594F",
          }}
        >
          <div className="flex flex-col">
            <CardTitle className="text-sm font-big">
              Download Invoices
            </CardTitle>
            <p className="text-xs text-muted-foreground">
              Get all invoices in a single file
            </p>
          </div>
          <DownloadIcon
            className="h-16 w-16 text-muted-foreground"
            style={{ color: "#00594F" }}
          />
        </button>
      </div>
      <div className="grid grid-cols-1 gap-4">
        <Card
          className="shadow-lg rounded-2xl bg-white"
          style={{
            background: "linear-gradient(to bottom, white, #F4F4F4)",
          }}
        >
          <CardHeader>
            <CardTitle>Last Invoices</CardTitle>
          </CardHeader>
          <CardContent>
            <InvoiceTable />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Invoices;
