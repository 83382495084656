import React from "react";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const PageTitle: React.FC = () => {
  const location = useLocation();

  const getPageTitle = () => {
    switch (location.pathname) {
      case "/dashboard":
        return "Dashboard";
      case "/invoices":
        return "Invoices";
      case "/signup":
        return "IQANA SIGN-IN";
      case "/settings":
        return "Settings";
      default:
        return "Iqana Portal"; // Fallback or default title
    }
  };

  return (
    <Typography
      variant="h6"
      sx={{
        color: "#00F25E",
        marginLeft: 1,
        fontWeight: "normal",
        fontSize: "1.50rem",
      }}
    >
      {getPageTitle()}
    </Typography>
  );
};

export default PageTitle;
