import React from 'react';
import { useSpring, animated } from 'react-spring';

interface CounterProps {
  from: number;
  to: number;
  format?: (value: number) => string;
}

const Counter: React.FC<CounterProps> = ({ from, to, format }) => {
  const { number } = useSpring({
    from: { number: from },
    number: to,
    delay: 200,
    config: { mass: 1, tension: 210, friction: 20 },
  });

  return <animated.div>{number.to(n => (format ? format(n) : n.toFixed(0)))}</animated.div>;
};

export default Counter;
