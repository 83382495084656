import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Switch,
  Card,
  CardContent,
} from "@mui/material";

const Settings: React.FC = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(to bottom, white, #F4F4F4)",
        minHeight: "100vh",
        padding: "2rem",
      }}
    >
      <h1
        className="text-4xl mb-8"
        style={{ color: "#00594F", marginTop: "1.5vh" }}
      >
        Customize <span style={{ fontWeight: "bold" }}> Your</span> Preferences
      </h1>

      <Grid container spacing={3}>
        {/* Connected Exchange */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              borderRadius: "1rem",
              height: "100%",
              background: "linear-gradient(to bottom, white, #F4F4F4)",
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: "1rem" }}>
                Connected Exchange
              </Typography>
              <Typography variant="body2" sx={{ mb: "2rem" }}>
                The exchange you are currently connected to.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: "2rem",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ color: "#00594F", fontWeight: "bold" }}
                  >
                    Coinbase
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#00594F" }}>
                    Connected since May 2023
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  sx={{
                    color: "#00594F",
                    borderColor: "#00594F",
                    marginTop: "1vh",
                  }}
                >
                  Request Disconnection
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Profile */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              borderRadius: "1rem",
              height: "100%",
              background: "linear-gradient(to bottom, white, #F4F4F4)",
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: "1rem" }}>
                Profile
              </Typography>
              <Typography variant="body2" sx={{ mb: "2rem" }}>
                Update your personal information.
              </Typography>
              <TextField
                fullWidth
                label="Name"
                placeholder="John Doe"
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#00594F" },
                  },
                  "& .MuiInputLabel-root": { color: "#00594F" },
                }}
              />
              <TextField
                fullWidth
                label="Email"
                type="email"
                placeholder="m@example.com"
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#00594F" },
                  },
                  "& .MuiInputLabel-root": { color: "#00594F" },
                }}
              />
              <TextField
                fullWidth
                label="Password"
                type="password"
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#00594F" },
                  },
                  "& .MuiInputLabel-root": { color: "#00594F" },
                }}
              />
              <Button
                variant="contained"
                fullWidth
                sx={{
                  mt: "2rem",
                  backgroundColor: "#00F25E",
                  color: "#00594F",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#00c94e",
                  },
                }}
              >
                Save Changes
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Notifications */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              borderRadius: "1rem",
              height: "100%",
              background: "linear-gradient(to bottom, white, #F4F4F4)",
            }}
          >
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: "1rem" }}>
                Notifications
              </Typography>
              <Typography variant="body2" sx={{ mb: "2rem" }}>
                Manage your notification preferences.
              </Typography>
              <Box
                sx={{
                  mb: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ color: "#00594F", fontWeight: "bold", mb: "0.5rem" }}
                  >
                    Email Notifications
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "#00594F", mb: "0.5rem" }}
                  >
                    Receive updates and alerts via email.
                  </Typography>
                </Box>
                <Switch />
              </Box>
              <Box
                sx={{
                  mb: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ color: "#00594F", fontWeight: "bold", mb: "0.5rem" }}
                  >
                    Quarterly Reports
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "#00594F", mb: "0.5rem" }}
                  >
                    Receive quarterly reports of market and Iqana updates.
                  </Typography>
                </Box>
                <Switch />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;
