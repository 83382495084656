import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../@/components/ui/card";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { PieChart, Pie, Cell, Legend, Sector } from "recharts";
import { TooltipProps } from "recharts";
import {
    NameType,
    ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import React, { useState } from "react";

import {
    LandmarkIcon,
    TrendingUpIcon,
    WalletIcon,
    BarChartIcon,
} from "lucide-react";
import Navigation from "../Components/Navigation"; // Import the navigation component
import TradingViewChart from "../Components/TradingViewChart";
import TradingTable from "../Components/TradingTable";
import { Title } from "@mui/icons-material";
import Counter from "./Counter";

const mockBalanceData = [
    { name: "Jan", Iqana: 4000, CryptoMarketCap: 2400, "S&P 500": 2400 },
    { name: "Feb", Iqana: 3000, CryptoMarketCap: 1398, "S&P 500": 2210 },
    { name: "Mar", Iqana: 2000, CryptoMarketCap: 9800, "S&P 500": 2290 },
    { name: "Apr", Iqana: 2780, CryptoMarketCap: 3908, "S&P 500": 2000 },
    { name: "May", Iqana: 1890, CryptoMarketCap: 4800, "S&P 500": 2181 },
    { name: "Jun", Iqana: 2390, CryptoMarketCap: 3800, "S&P 500": 2500 },
    { name: "Jul", Iqana: 3490, CryptoMarketCap: 4300, "S&P 500": 2100 },
];

const mockPositionsData = [
    { name: "USDC", value: 442000.7, percent: 0.5 },
    { name: "ETH", value: 185400.9, percent: 0.11392 },
    { name: "BTC", value: 242200.4, percent: 0.3791 },
    { name: "RNDR", value: 84323.3 },
];
interface PositionData {
    name: string;
    value: number;
    percent?: number; // Optional, will be calculated
}
const formatCurrency = (value: any) => {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(value);
};
const calculatePercentages = (data: PositionData[]) => {
    const totalValue = data.reduce((acc, position) => acc + position.value, 0);

    return data.map((position: PositionData) => ({
        ...position,
        percent: position.value / totalValue,
    }));
};

const logos: { [key in "USDC" | "ETH" | "BTC" | "RNDR"]: string } = {
    USDC: "/usd-coin-usdc-logo.png",
    ETH: "/ethereum-eth-logo.png",
    BTC: "/bitcoin-btc-logo.png",
    RNDR: "/render-token-logo.png",
};
const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];
const COLORS_PIECHART = ["#4682B4", "#333333", "#f7931a", "#e71a43"]; // Smooth shades for usdc, eth, btc, reder

const CustomTooltip = ({
    active,
    payload,
}: {
    active?: boolean;
    payload?: any[];
}) => {
    if (active && payload && payload.length) {
        const { name, value, percent } = payload[0].payload;
        return (
            <div
                className="custom-tooltip"
                style={{
                    backgroundColor: "#F4F4F4",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                }}
            >
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <strong>Asset: </strong>
                            </td>
                            <td>{name}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Value: </strong>
                            </td>
                            <td>{value}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Size: </strong>
                            </td>
                            <td>{(percent * 100).toFixed(2)}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return null;
};
const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <text
                x={cx}
                y={cy - 20}
                dy={8}
                textAnchor="middle"
                fill={fill}
                fontSize="25"
                fontWeight="bold"
            >
                {payload.name}
            </text>
            {logos[payload.name as keyof typeof logos] && (
                <image
                    x={cx - 20}
                    y={cy}
                    href={logos[payload.name as keyof typeof logos]}
                    height="40"
                    width="40"
                />
            )}
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#333"
                fontSize="16"
                fontWeight="bold"
            >
                {formatCurrency(value)}
            </text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#999"
            >
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const renderCustomizedLabel = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, index, fill, name } =
        props;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const xLine = cx + (outerRadius + 20) * Math.cos(-midAngle * RADIAN);
    const yLine = cy + (outerRadius + 20) * Math.sin(-midAngle * RADIAN);
    const xText = cx + (outerRadius + 40) * Math.cos(-midAngle * RADIAN);
    const yText = cy + (outerRadius + 40) * Math.sin(-midAngle * RADIAN);

    return (
        <g>
            <line
                x1={x}
                y1={y}
                x2={xLine}
                y2={yLine}
                stroke={fill}
                strokeWidth={2}
            />
            <text
                x={xText}
                y={yText}
                fill={fill}
                textAnchor={xText > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {name}
            </text>
        </g>
    );
};

const Dashboard = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const onPieEnter = (_: any, index: number) => {
        setActiveIndex(index);
    };
    return (
        <div
            className="p-8 min-h-screen"
            style={{
                background: "linear-gradient(to bottom, #FFFFFF, #FFFFFF)",
            }}
        >
            <h1
                className="text-4xl mb-8"
                style={{ color: "#00594F", marginTop: "1.5vh" }}
            >
                Welcome back,
                <span style={{ fontWeight: "bold" }}>Q. Allard</span>
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
                <Card
                    className="shadow-lg rounded-2xl"
                    style={{
                        background:
                            "linear-gradient(to bottom, white, #F4F4F4)",
                    }}
                >
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-3 pb-2">
                        <div className="flex flex-col">
                            <CardTitle className="text-sm font-big">
                                Total Invested
                            </CardTitle>
                            <div className="text-2xl font-bold">
                                <Counter
                                    from={0}
                                    to={402643}
                                    format={(value) =>
                                        `$${new Intl.NumberFormat(
                                            "en-US"
                                        ).format(value)}`
                                    }
                                />
                            </div>
                            <p className="text-xs text-muted-foreground">
                                In a total of 3 deposits
                            </p>
                        </div>
                        <LandmarkIcon
                            className="h-16 w-16"
                            style={{ color: "#00594F" }}
                        />
                    </CardHeader>
                </Card>

                <Card
                    className="shadow-lg rounded-2xl"
                    style={{
                        background:
                            "linear-gradient(to bottom, white, #F4F4F4)",
                    }}
                >
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-3 pb-2">
                        <div className="flex flex-col">
                            <CardTitle className="text-sm font-big">
                                Profit / Loss
                            </CardTitle>
                            <div className="text-2xl font-bold">
                                <Counter
                                    from={0}
                                    to={356341}
                                    format={(value) =>
                                        `$${new Intl.NumberFormat(
                                            "en-US"
                                        ).format(value)}`
                                    }
                                />
                            </div>
                            <p className="text-xs text-muted-foreground">
                                +79% Return on investment
                            </p>
                        </div>
                        <TrendingUpIcon
                            className="h-16 w-16 text-muted-foreground"
                            style={{ color: "#00594F" }}
                        />
                    </CardHeader>
                </Card>
                <Card
                    className="shadow-lg rounded-2xl"
                    style={{
                        background:
                            "linear-gradient(to bottom, white, #F4F4F4)",
                    }}
                >
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-3 pb-2">
                        <div className="flex flex-col">
                            <CardTitle className="text-sm font-big">
                                Current Balance
                            </CardTitle>
                            <div className="text-2xl font-bold">
                                <Counter
                                    from={0}
                                    to={758984}
                                    format={(value) =>
                                        `$${new Intl.NumberFormat(
                                            "en-US"
                                        ).format(value)}`
                                    }
                                />
                            </div>
                            <p className="text-xs text-muted-foreground">
                                +18.2% from last month
                            </p>
                        </div>
                        <WalletIcon
                            className="h-16 w-16 text-muted-foreground"
                            style={{ color: "#00594F" }}
                        />
                    </CardHeader>
                </Card>
                <Card
                    className="shadow-lg rounded-2xl"
                    style={{
                        background:
                            "linear-gradient(to bottom, white, #F4F4F4)",
                    }}
                >
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-3 pb-2">
                        <div className="flex flex-col">
                            <CardTitle className="text-sm font-big">
                                Number of Trades
                            </CardTitle>
                            <div className="text-2xl font-bold">
                                <Counter
                                    from={0}
                                    to={64}
                                    format={(value) => `${value.toFixed(0)}`}
                                />
                            </div>
                            <p className="text-xs text-muted-foreground">
                                In 16 months of activity
                            </p>
                        </div>
                        <BarChartIcon
                            className="h-16 w-16 text-muted-foreground"
                            style={{ color: "#00594F" }}
                        />
                    </CardHeader>
                </Card>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-9 gap-6 mb-8">
                <Card
                    className="shadow-lg rounded-2xl bg-white col-span-6"
                    style={{
                        background:
                            "linear-gradient(to bottom, white, #F4F4F4)",
                        height: "100%",
                    }}
                >
                    <CardContent
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <CardTitle
                            className="text-2xl"
                            style={{
                                textAlign: "center",
                                marginTop: "10px",
                                marginBottom: "-5px",
                            }}
                        >
                            Account Value Overview (USD)
                        </CardTitle>
                        <TradingViewChart />
                        {/* Use the TradingViewChart component */}
                    </CardContent>
                </Card>

                <Card
                    className="shadow-lg rounded-2xl bg-white col-span-3"
                    style={{
                        background:
                            "linear-gradient(to bottom, white, #F4F4F4)",
                        overflow: "visible",
                    }}
                >
                    <CardHeader className="flex justify-center">
                        <CardTitle className="text-center">
                            Current Holdings
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="pt-2">
                        <ResponsiveContainer
                            width="100%"
                            height={380}
                            style={{ overflow: "visible" }}
                        >
                            <PieChart>
                                <Pie
                                    activeIndex={activeIndex}
                                    activeShape={renderActiveShape}
                                    data={calculatePercentages(
                                        mockPositionsData
                                    )}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={80}
                                    outerRadius={125}
                                    fill="#8884d8"
                                    dataKey="value"
                                    onMouseEnter={onPieEnter}
                                >
                                    {calculatePercentages(
                                        mockPositionsData
                                    ).map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={
                                                COLORS_PIECHART[
                                                    index %
                                                        COLORS_PIECHART.length
                                                ]
                                            }
                                        />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </div>
            <div className="grid grid-cols-1 gap-4">
                <Card
                    className="shadow-lg rounded-2xl bg-white"
                    style={{
                        background:
                            "linear-gradient(to bottom, white, #F4F4F4)",
                    }}
                >
                    <CardHeader>
                        <CardTitle>Trades History</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <TradingTable />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default Dashboard;
