import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import Flag from "react-world-flags";
import { Link, Routes, Route } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import Invoices from "./Components/Invoices";
import Signup from "./Components/SignUp";
import Signin from "./Components/SignIn";
import Settings from "./Components/Settings";

import PageTitle from "./Components/PageTitle"; // Import the PageTitle component
import { ReactComponent as IqanaSymbolRgb } from "./images/iqana_symbol_rgb.svg";
import { useLocation } from "react-router-dom";

const drawerWidth = 240;

const App: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCountry, setSelectedCountry] = useState<string>("US");
  const location = useLocation();
  const isSignupPage = ["/signup", "/signin"].includes(location.pathname);
  const handleDrawerOpen = () => setSidebarOpen(true);
  const handleDrawerClose = () => setSidebarOpen(false);
  const handleLanguageClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleLanguageClose = () => setAnchorEl(null);
  const handleLanguageChange = (countryCode: string) => {
    setSelectedCountry(countryCode);
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", height: "100vh", width: "100vw" }}>
      <CssBaseline />
      {!isSignupPage && ( // Conditionally render the AppBar only if it's not the signup page
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            height: 56,
            backgroundColor: "#00594F",
            width: sidebarOpen ? `calc(100% - ${drawerWidth}px)` : "100%",
          }}
        >
          <Toolbar
            sx={{
              minHeight: 56,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleDrawerOpen}
            >
              <IqanaSymbolRgb
                style={{
                  cursor: "pointer",
                  height: 32,
                  width: 32,
                }}
              />
              <PageTitle /> {/* Display the dynamic page title here */}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={handleLanguageClick}
                sx={{
                  p: 0,
                  borderRadius: "50%",
                  overflow: "hidden",
                  marginRight: "2vw",
                }}
              >
                <Flag
                  code={selectedCountry}
                  style={{ width: 32, height: 32 }}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleLanguageClose}
              >
                <MenuItem onClick={() => handleLanguageChange("US")}>
                  <Flag code="US" style={{ width: 32, height: 32 }} />
                </MenuItem>
                <MenuItem onClick={() => handleLanguageChange("ES")}>
                  <Flag code="ES" style={{ width: 32, height: 32 }} />
                </MenuItem>
                <MenuItem onClick={() => handleLanguageChange("DE")}>
                  <Flag code="DE" style={{ width: 32, height: 32 }} />
                </MenuItem>
              </Menu>
              <IconButton color="inherit" sx={{ marginRight: "2vw" }}>
                <LogoutIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        variant="persistent"
        anchor="left"
        open={sidebarOpen}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#00594F",
            color: "#FFFFFF",
          },
        }}
      >
        <Box sx={{ overflow: "auto" }}>
          <List>
            <ListItem button onClick={handleDrawerClose}>
              <IconButton edge="start" color="inherit">
                <ChevronLeftIcon />
              </IconButton>
              <ListItemText primary="Hide " />
            </ListItem>
            <ListItem button>
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                <Button
                  sx={{
                    color: "#FFFFFF",
                    justifyContent: "flex-start",
                    textTransform: "none",
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                  }}
                >
                  Dashboard
                </Button>
              </Link>
            </ListItem>
            <ListItem button>
              <Link
                to="/invoices"
                style={{
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                <Button
                  sx={{
                    color: "#FFFFFF",
                    justifyContent: "flex-start",
                    textTransform: "none",
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                  }}
                >
                  Invoices
                </Button>
              </Link>
            </ListItem>
            <ListItem button>
              <Link
                to="/settings"
                style={{
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                <Button
                  sx={{
                    color: "#FFFFFF",
                    justifyContent: "flex-start",
                    textTransform: "none",
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                  }}
                >
                  Settings
                </Button>
              </Link>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          mt: !isSignupPage ? 5 : 0, // Adjust margin top based on whether it's the signup page
          transition: "margin 0.3s",
          marginLeft: sidebarOpen ? `${drawerWidth}` : -30,
          height: "calc(100vh)",
          overflow: "auto",
        }}
      >
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/" element={<Dashboard />} /> {/* Default route */}
        </Routes>
      </Box>
    </Box>
  );
};

export default App;
