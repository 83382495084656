import React from "react";
import { Box, Typography } from "@mui/material";

interface SignupCardProps {
    icon: React.ReactNode;
    title: string;
    description: string;
    newLabel?: boolean;
    newLabelText: string;
}

const SignupCard: React.FC<SignupCardProps> = ({
    icon,
    title,
    description,
    newLabel,
    newLabelText,
}) => {
    return (
        <Box
            sx={{
                background: "#F4F4F4", // Solid white background
                borderRadius: "1rem",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                height: "100%",
                minHeight: "12rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "1.5rem",
                gap: "1rem",
                position: "relative",
            }}
        >
            {newLabel && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "1rem",
                        right: "1rem",
                        backgroundColor: "#00F25E",
                        color: "#00594F",
                        borderRadius: "1rem",
                        padding: "0.25rem 0.5rem",
                        fontSize: "0.75rem",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                    }}
                >
                    {newLabelText}
                </Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "center", mb: 0 }}>
                {icon}
            </Box>
            <Typography
                variant="h6"
                color={"black"}
                sx={{ fontWeight: "bold", textAlign: "center" }}
            >
                {title}
            </Typography>
            <Typography
                variant="body2"
                color={"black"}
                sx={{ textAlign: "center" }}
            >
                {description}
            </Typography>
        </Box>
    );
};

export default SignupCard;
