import React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import CustomSwitchComponent from './CustomSwitchComponent';

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

interface Data {
  pair: string;
  entry_price: string;
  exit_price: string;
  size: string;
  entry_date: string;
  exit_date: string;
  total_fee: string;
  side: string;
  volume: string;
  leverage: string;
  pnl: string;
  pnl_percentage: string;
  exchange: string;
}

const rows: Data[] = [
  {
    pair: 'ETH/USD',
    entry_price: '2150',
    exit_price: '2200',
    size: '93.02 ETH',
    entry_date: '2024-01-05',
    exit_date: '2024-01-07',
    total_fee: '400',
    side: 'buy',
    volume: '200000',
    leverage: '1x',
    pnl: '4651',
    pnl_percentage: '2.33%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '2250',
    exit_price: '2300',
    size: '155.56 ETH',
    entry_date: '2024-01-15',
    exit_date: '2024-01-18',
    total_fee: '700',
    side: 'buy',
    volume: '350000',
    leverage: '1x',
    pnl: '7778',
    pnl_percentage: '2.22%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '2400',
    exit_price: '2350',
    size: '166.67 ETH',
    entry_date: '2024-01-25',
    exit_date: '2024-01-28',
    total_fee: '800',
    side: 'sell',
    volume: '400000',
    leverage: '1x',
    pnl: '-8333',
    pnl_percentage: '-2.08%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '2500',
    exit_price: '2600',
    size: '115.38 ETH',
    entry_date: '2024-02-05',
    exit_date: '2024-02-08',
    total_fee: '600',
    side: 'buy',
    volume: '288462',
    leverage: '1x',
    pnl: '11538',
    pnl_percentage: '4.00%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '2700',
    exit_price: '2750',
    size: '107.14 ETH',
    entry_date: '2024-02-15',
    exit_date: '2024-02-18',
    total_fee: '580',
    side: 'buy',
    volume: '289286',
    leverage: '1x',
    pnl: '5357',
    pnl_percentage: '1.85%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '2850',
    exit_price: '2800',
    size: '122.81 ETH',
    entry_date: '2024-02-25',
    exit_date: '2024-02-28',
    total_fee: '700',
    side: 'sell',
    volume: '350000',
    leverage: '1x',
    pnl: '-6140',
    pnl_percentage: '-1.75%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '2950',
    exit_price: '3050',
    size: '127.12 ETH',
    entry_date: '2024-03-05',
    exit_date: '2024-03-08',
    total_fee: '750',
    side: 'buy',
    volume: '375000',
    leverage: '1x',
    pnl: '12712',
    pnl_percentage: '3.39%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '3150',
    exit_price: '3200',
    size: '95.24 ETH',
    entry_date: '2024-03-15',
    exit_date: '2024-03-18',
    total_fee: '600',
    side: 'buy',
    volume: '300000',
    leverage: '1x',
    pnl: '4762',
    pnl_percentage: '1.59%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '3300',
    exit_price: '3250',
    size: '112.12 ETH',
    entry_date: '2024-03-25',
    exit_date: '2024-03-28',
    total_fee: '740',
    side: 'sell',
    volume: '370000',
    leverage: '1x',
    pnl: '-5606',
    pnl_percentage: '-1.52%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '3400',
    exit_price: '3500',
    size: '85.29 ETH',
    entry_date: '2024-04-05',
    exit_date: '2024-04-08',
    total_fee: '580',
    side: 'buy',
    volume: '290000',
    leverage: '1x',
    pnl: '8529',
    pnl_percentage: '2.94%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '3550',
    exit_price: '3600',
    size: '101.41 ETH',
    entry_date: '2024-04-15',
    exit_date: '2024-04-18',
    total_fee: '720',
    side: 'buy',
    volume: '360000',
    leverage: '1x',
    pnl: '5070',
    pnl_percentage: '1.41%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '3650',
    exit_price: '3600',
    size: '82.19 ETH',
    entry_date: '2024-04-25',
    exit_date: '2024-04-28',
    total_fee: '600',
    side: 'sell',
    volume: '300000',
    leverage: '1x',
    pnl: '-4110',
    pnl_percentage: '-1.37%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '3700',
    exit_price: '3800',
    size: '97.30 ETH',
    entry_date: '2024-05-05',
    exit_date: '2024-05-08',
    total_fee: '720',
    side: 'buy',
    volume: '360000',
    leverage: '1x',
    pnl: '9730',
    pnl_percentage: '2.70%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '3850',
    exit_price: '3900',
    size: '77.92 ETH',
    entry_date: '2024-05-15',
    exit_date: '2024-05-18',
    total_fee: '600',
    side: 'buy',
    volume: '300000',
    leverage: '1x',
    pnl: '3896',
    pnl_percentage: '1.30%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '3950',
    exit_price: '3900',
    size: '88.61 ETH',
    entry_date: '2024-05-25',
    exit_date: '2024-05-28',
    total_fee: '700',
    side: 'sell',
    volume: '350000',
    leverage: '1x',
    pnl: '-4430',
    pnl_percentage: '-1.27%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '3975',
    exit_price: '4000',
    size: '75.47 ETH',
    entry_date: '2024-06-05',
    exit_date: '2024-06-08',
    total_fee: '600',
    side: 'buy',
    volume: '300000',
    leverage: '1x',
    pnl: '1887',
    pnl_percentage: '0.63%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '3900',
    exit_price: '3950',
    size: '89.74 ETH',
    entry_date: '2024-06-15',
    exit_date: '2024-06-18',
    total_fee: '700',
    side: 'buy',
    volume: '350000',
    leverage: '1x',
    pnl: '4487',
    pnl_percentage: '1.28%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '3925',
    exit_price: '3975',
    size: '101.91 ETH',
    entry_date: '2024-06-25',
    exit_date: '2024-06-28',
    total_fee: '800',
    side: 'buy',
    volume: '400000',
    leverage: '1x',
    pnl: '5095',
    pnl_percentage: '1.27%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '3950',
    exit_price: '3925',
    size: '86.08 ETH',
    entry_date: '2024-07-05',
    exit_date: '2024-07-08',
    total_fee: '680',
    side: 'sell',
    volume: '340000',
    leverage: '1x',
    pnl: '-2152',
    pnl_percentage: '-0.63%',
    exchange: 'OKX',
  },
  {
    pair: 'ETH/USD',
    entry_price: '3975',
    exit_price: '4000',
    size: '90.57 ETH',
    entry_date: '2024-07-15',
    exit_date: '2024-07-18',
    total_fee: '720',
    side: 'buy',
    volume: '360000',
    leverage: '1x',
    pnl: '2264',
    pnl_percentage: '0.63%',
    exchange: 'OKX',
  },
];
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
type Order = 'asc' | 'desc';
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  { id: 'entry_date', numeric: false, disablePadding: false, label: 'Entry Date' },
  { id: 'exit_date', numeric: false, disablePadding: false, label: 'Exit Date' },
  { id: 'entry_price', numeric: true, disablePadding: false, label: 'Entry Price' },
  { id: 'exit_price', numeric: true, disablePadding: false, label: 'Exit Price' },
  { id: 'pair', numeric: false, disablePadding: true, label: 'Pair' },
  { id: 'size', numeric: false, disablePadding: false, label: 'Size' },
  { id: 'side', numeric: false, disablePadding: false, label: 'Side' },
  { id: 'pnl', numeric: true, disablePadding: false, label: 'P&L' },
  { id: 'pnl_percentage', numeric: true, disablePadding: false, label: 'P&L Percentage' },
  { id: 'total_fee', numeric: true, disablePadding: false, label: 'Total Fee' },
  { id: 'volume', numeric: true, disablePadding: false, label: 'Volume' },
  { id: 'leverage', numeric: false, disablePadding: false, label: 'Leverage' },
  { id: 'exchange', numeric: false, disablePadding: false, label: 'Exchange' },
];
const columns = [
  { Header: 'Entry Date', accessor: 'entry_date' },
  { Header: 'Exit Date', accessor: 'exit_date' },
  { Header: 'Entry Price', accessor: 'entry_price' },
  { Header: 'Exit Price', accessor: 'exit_price' },
  { Header: 'Pair', accessor: 'pair' },
  { Header: 'Size', accessor: 'size' },
  { Header: 'Side', accessor: 'side' },
  { Header: 'PnL', accessor: 'pnl' },
  { Header: 'PnL Percentage', accessor: 'pnl_percentage' },
  { Header: 'Total Fee', accessor: 'total_fee' },
  { Header: 'Volume', accessor: 'volume' },
  { Header: 'Leverage', accessor: 'leverage' },
  { Header: 'Exchange', accessor: 'exchange' },
];
interface EnhancedTableProps {
  order: Order;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id === 'entry_price' || headCell.id === 'exit_price' || headCell.numeric ? 'right' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: 'bold',
              backgroundColor: '#00594F',
              borderBottom: '2px solid #E0E0E0',
              color: '#FFFFFF', // Ensures the text color is white
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{
                '&.Mui-active': {
                  color: '#FFFFFF', // Ensures the text color is white when active
                },
                '& .MuiTableSortLabel-icon': {
                  color: '#FFFFFF !important', // Ensures the sort icon color is white
                },
                color: '#FFFFFF', // Ensures the text color is white
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}





interface EnhancedTableToolbarProps {
  numSelected: number;
}

function EnhancedTableToolbar() {
  return (
    <Toolbar
      sx={{
        pl: { sm: 1 }, // Adjust padding-left
        pr: { xs: 1, sm: 1 }, // Adjust padding-right
        pt: { sm: 1 }, // Adjust padding-top
        pb: { sm: 1 }, // Adjust padding-bottom
      }}
    >
    </Toolbar>
  );
}


export default function TradingTable() {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('pair');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.pair);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(() => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), [order, orderBy, page, rowsPerPage]);

  return (
    <Box sx={{ width: '100%' }}>
  <Paper sx={{ width: '100%', mb: 2, borderRadius: 2 }}>
    <TableContainer sx={{ borderRadius: 2 }}>
      <Table sx={{ minWidth: 750, width: '100%' }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
  {visibleRows.map((row, index) => {
    const labelId = `enhanced-table-checkbox-${index}`;

    return (
      <TableRow key={row.pair} sx={{ cursor: 'pointer' }}>
        <TableCell component="th" id={labelId} scope="row" padding="none" align="right">
          {row.entry_date}
        </TableCell>
        <TableCell align="right">{row.exit_date}</TableCell>
        <TableCell align="right">{formatter.format(parseFloat(row.entry_price))}</TableCell>
        <TableCell align="right">{formatter.format(parseFloat(row.exit_price))}</TableCell>
        <TableCell align="right">{row.pair}</TableCell>
        <TableCell align="right">{row.size}</TableCell>
        <TableCell align="right">{row.side}</TableCell>
        <TableCell align="right">{formatter.format(parseFloat(row.pnl))}</TableCell>
        <TableCell align="right">{row.pnl_percentage}</TableCell>
        <TableCell align="right">{formatter.format(parseFloat(row.total_fee))}</TableCell>
        <TableCell align="right">{formatter.format(parseFloat(row.volume))}</TableCell>
        <TableCell align="right">{row.leverage}</TableCell>
        <TableCell align="right">{row.exchange}</TableCell>
      </TableRow>
    );
  })}
  {emptyRows > 0 && (
    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
      <TableCell colSpan={13} />
    </TableRow>
  )}
</TableBody>


      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Paper>
  <CustomSwitchComponent dense={dense} handleChangeDense={handleChangeDense} />
  </Box>

  );
}