import React, { useEffect, useRef, useState } from 'react';
import { createChart, AreaData, IChartApi, ISeriesApi, Time, LineData, LogicalRange, MouseEventParams, LineStyle } from 'lightweight-charts';
import Papa from 'papaparse';

const TradingViewChart: React.FC = () => {
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const [chart, setChart] = useState<IChartApi | null>(null);
  const [areaSeries, setAreaSeries] = useState<ISeriesApi<'Area'> | null>(null);
  const [lineSeries, setLineSeries] = useState<ISeriesApi<'Line'> | null>(null);
  const [data, setData] = useState<AreaData<Time>[]>([]);
  const [measurement, setMeasurement] = useState<{ start: LineData | null, end: LineData | null }>({ start: null, end: null });
  const [selectedButton, setSelectedButton] = useState<string | null>(null);
  const [percentageChange, setPercentageChange] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/account_data.csv'); // Ensure this path is correct
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const csvData = await response.text();
        const parsedData = Papa.parse(csvData, { header: true, dynamicTyping: true }).data as { date: string, balance: number }[];

        const areaData: AreaData<Time>[] = parsedData.map((item) => ({
          time: item.date,
          value: item.balance,
        }));

        setData(areaData);
      } catch (error) {
        console.error("Error fetching and parsing CSV data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!chartContainerRef.current) {
      return;
    }

    const chartInstance = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      timeScale: {
        minBarSpacing: 1,
      },
    });

    const areaSeriesInstance = chartInstance.addAreaSeries({
      topColor: 'rgba(0, 89, 79, 0.7)', // Gradient top color
      bottomColor: 'rgba(0, 89, 79, 0.3)', // Gradient bottom color
      lineColor: '#00594F', // Line color
      priceFormat: {
        type: 'custom',
        formatter: (price: number) => price.toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      },
    });

    const lineSeriesInstance = chartInstance.addLineSeries({
      color: 'black',
      lineWidth: 1,
      lineStyle: LineStyle.Dashed
    });

    setChart(chartInstance);
    setAreaSeries(areaSeriesInstance);
    setLineSeries(lineSeriesInstance);

    const handleResize = () => {
      if (chartContainerRef.current) {
        chartInstance.resize(chartContainerRef.current.clientWidth, chartContainerRef.current.clientHeight);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (chartContainerRef.current) {
      resizeObserver.observe(chartContainerRef.current);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      resizeObserver.disconnect();
      chartInstance.remove();
    };
  }, []);

  useEffect(() => {
    if (areaSeries && data.length > 0) {
      areaSeries.setData(data);

      const lineData: LineData[] = data.map(point => ({
        time: point.time,
        lineWidth: 0.1, // Thinner line
        lineStyle: LineStyle.Dotted, // Dotted line
        value: 788420 //473520, 788420
      }));

      if (lineSeries) {
        lineSeries.setData(lineData);
        lineSeries.setMarkers([
          {
            time: data[Math.floor(data.length / 10)].time, // Label at the middle of the data
            position: 'aboveBar',
            color: 'black',
            shape: 'arrowUp',
            text: 'High-Water Mark',
          },
        ]);
      }

      if (chart) {
        const firstTime = data[0].time;
        const lastTime = data[data.length - 1].time;

        chart.timeScale().setVisibleRange({
          from: firstTime,
          to: lastTime,
        });

        chart.timeScale().subscribeVisibleLogicalRangeChange((logicalRange: LogicalRange | null) => {
          if (!logicalRange) return;

          const visibleRange = chart.timeScale().getVisibleRange();
          if (!visibleRange) return;

          const firstDate = new Date(firstTime.toString()).getTime();
          const lastDate = new Date(lastTime.toString()).getTime();

          const visibleFrom = new Date(visibleRange.from.toString()).getTime();
          const visibleTo = new Date(visibleRange.to.toString()).getTime();

          const newFrom = visibleFrom < firstDate ? firstTime : visibleRange.from;
          const newTo = visibleTo > lastDate ? lastTime : visibleRange.to;

          chart.timeScale().setVisibleRange({
            from: newFrom,
            to: newTo,
          });

          updatePercentageChange();
        });
      }
    }
  }, [areaSeries, data, chart, lineSeries]);

  const handleMouseClick = (param: MouseEventParams) => {
    if (!param.time || !param.point || !chart || !areaSeries) {
      return;
    }

    const price = areaSeries.coordinateToPrice(param.point.y);
    if (price === null) {
      return; // Ignore if price is null
    }
    const time = param.time as Time;

    if (!measurement.start) {
      setMeasurement({ start: { time, value: price }, end: null });
    } else if (!measurement.end) {
      setMeasurement((prev) => ({ ...prev, end: { time, value: price } }));
    } else {
      setMeasurement({ start: { time, value: price }, end: null });
    }
  };

  useEffect(() => {
    if (!chart) return;

    chart.subscribeClick(handleMouseClick);

    return () => {
      chart.unsubscribeClick(handleMouseClick);
    };
  }, [chart]);

  const renderMeasurement = () => {
    if (!measurement.start || !measurement.end || !chart) {
      return null;
    }

    const startPrice = measurement.start.value;
    const endPrice = measurement.end.value;
    const startDate = measurement.start.time;
    const endDate = measurement.end.time;

    const priceChange = ((endPrice - startPrice) / startPrice) * 100;
    const color = priceChange > 0 ? 'blue' : 'red';

    const lineSeriesInstance = chart.addLineSeries({
      color: color,
      lineWidth: 2,
    });

    lineSeriesInstance.setData([measurement.start, measurement.end]);
    
    const text = `${priceChange.toFixed(2)}%`;

    lineSeriesInstance.setMarkers([
      {
        time: measurement.start.time,
        position: 'aboveBar',
        color: color,
        shape: 'arrowUp',
        text: text,
      },
    ]);

    return null;
  };

  useEffect(() => {
    renderMeasurement();
  }, [measurement]);

  const setVisibleRange = (days: number, button: string) => {
    if (!chart || data.length === 0) return;

    setSelectedButton(button);

    const to = new Date(data[data.length - 1].time.toString()).getTime();
    const from = to - days * 24 * 60 * 60 * 1000;

    chart.timeScale().setVisibleRange({
      from: new Date(from).toISOString().split('T')[0] as Time,
      to: new Date(to).toISOString().split('T')[0] as Time,
    });
  };

  const showAllData = () => {
    if (!chart || data.length === 0) return;

    setSelectedButton('ALL');

    const firstTime = data[0].time;
    const lastTime = data[data.length - 1].time;

    chart.timeScale().setVisibleRange({
      from: firstTime,
      to: lastTime,
    });
  };

  const showYTD = () => {
    if (!chart || data.length === 0) return;

    setSelectedButton('YTD');

    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const to = now.getTime();
    const from = startOfYear.getTime();

    chart.timeScale().setVisibleRange({
      from: new Date(from).toISOString().split('T')[0] as Time,
      to: new Date(to).toISOString().split('T')[0] as Time,
    });
  };

  const getButtonStyle = (button: string) => ({
    width: '40px',
    margin: '0 5px',
    padding: '5px 10px',
    borderRadius: '5px',
    border: '1px solid rgba(0, 89, 79, 0.7)',
    background: selectedButton === button ? 'rgba(0, 89, 79, 1)' : 'rgba(0, 89, 79, 0.7)',
    color: 'white',
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
  });

  const updatePercentageChange = () => {
    if (!chart || data.length === 0) return;

    const visibleRange = chart.timeScale().getVisibleRange();
    if (!visibleRange) return;

    const visibleData = data.filter(
      d => new Date(d.time.toString()).getTime() >= new Date(visibleRange.from.toString()).getTime() &&
           new Date(d.time.toString()).getTime() <= new Date(visibleRange.to.toString()).getTime()
    );

    if (visibleData.length < 2) {
      setPercentageChange(null);
      return;
    }

    const firstValue = visibleData[0].value;
    const lastValue = visibleData[visibleData.length - 1].value;

    const change = ((lastValue - firstValue) / firstValue) * 100;
    setPercentageChange(change);
  };

  return (
    <div style={{ width: '100%', height: '100%', marginTop: '2vh', display: 'flex', flexDirection: 'column', borderRadius: '15px', overflow: 'hidden', position: 'relative' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px', marginLeft: '1vw' }}>
        <button onClick={() => setVisibleRange(7, '1W')} style={getButtonStyle('1W')}>1W</button>
        <button onClick={() => setVisibleRange(30, '1M')} style={getButtonStyle('1M')}>1M</button>
        <button onClick={() => setVisibleRange(365, '1Y')} style={getButtonStyle('1Y')}>1Y</button>
        <button onClick={showYTD} style={getButtonStyle('YTD')}>YTD</button>
        <button onClick={showAllData} style={getButtonStyle('ALL')}>ALL</button>
        {percentageChange !== null && (
          <div style={{
            marginLeft: '20px',
            padding: '5px 10px',
            borderRadius: '5px',
            border: '1px solid',
            borderColor: percentageChange > 0 ? 'green' : 'red',
            backgroundColor: percentageChange > 0 ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)',
            fontSize: '12px',
            fontWeight: 'bold',
            color: percentageChange > 0 ? 'green' : 'red',
          }}>
            {percentageChange.toFixed(2)}%
          </div>
        )}
      </div>
      <div ref={chartContainerRef} style={{ flex: 1 }} />
    </div>
  );
};

export default TradingViewChart;
